import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'

const Routes = (
  <Route path="orders">
    <Route path="confirmation/:orderToken" component={ Views.OrderConfirmation } />
    {/*<Route path=":id/edit" component={ Views.OrderEdit } />*/}
  </Route>
)

export default Routes
